.testimonial-container {
    position: relative;
    width: 100%;
    max-width: 600px;
    margin: 80px auto;
    padding: 20px;
   border-radius: 5px;
  }
  
  .testimonial {
    background-color: #f9f9f9;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    background-color: lightgray;
  }
  
  .testimonial-text {
    font-size: 18px;
    line-height: 1.5;
    margin-bottom: 25px;
    font-family: "Fira Sans Condensed", sans-serif;
    color: #262a56;
    font-weight: bold;
    
  }
  
  .testimonial-author {
    font-weight: bold;
    font-size: 14px;
    color: #ff6000;
  }
  
  .nav-button {
    background-color: transparent;
    border: none;
    font-size: 24px;
    color: #333;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .left-button {
    left: 0;
  }
  
  .right-button {
    right: 0;
  }
.nav-button2:hover{
  width: 7%;
  color: #262a56;
}
