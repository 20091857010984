/* PromoModal.css */
.promo-modal {
  max-width: 100vw;
  max-height: 100vh;
  
}
.promo-modal-content {
  background-color: #262a56;
  color: whitesmoke;
  max-height: 60vh;
  overflow-y: auto;
  padding: 5px;
  font-family: "Fira Sans Condensed", sans-serif;
}

.promo-modal-tagline {
  font-size: 1.2em;
  padding: 0.5em;
  color: #f7aa7b;
  @media (min-width: 600px) {
    font-size: 1.5em;
  }
}

.promo-modal-highlight {
  font-size: 1em;
  padding: 0.5em;
  font-weight: bold;

  @media (min-width: 600px) {
    font-size: 1.2em;
  }
}

.promo-modal-points p {
  margin-bottom: 8px;
  color: white;
}
.promo-modal-points strong {
  color: #f7aa7b;
}
.promo-modal-ending {
  margin-top: 16px;
}
.message {
  font-family: "Vina Sans", cursive;
  text-align: center;
  color: white;
}
.promo-modal-signature {
  margin-top: 16px;
  font-weight: bold;
  color: #85f08e;
  text-align: center;
}

.promo-modal-team {
  font-style: italic;
  margin-bottom: 16px;
  color: #85f08e;
  text-align: center;
  font-family: "Vina Sans", cursive;
}

/* Add additional styling as needed */
.close-button {
  font-size: 1.5em;
  padding: 0;
  margin: 2px;
  background-color: #ff4d4d;
  border-radius: 7px;
}

.close-icon {
  padding: 0;
  margin: 0;
  color: black;
}
